<template>
  <div class="about" v-html="content" />
</template>

<script>
import { getInfo } from '@/api/index'
export default {
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    this.getAboutInfo()
  },
  methods: {
    async getAboutInfo() {
      const res = await getInfo('about_msi', { id: this.$route.query.id })
      this.content = res.info.content
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  padding: 5px;
}
</style>
